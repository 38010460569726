export const Login = "login";
export const Signup = "user";
export const Product = "product";
export const Order = "order";
export const Profile = "profile";
export const Address = "address";
export const Contactus = "contact";
export const AddToCart = "addToCart";
export const Askquestion = "faq";
export const Post = "post";
export const Doctor = "doctor";
export const Chat = "chatfree";
export const Invoice = "invoice";
export const Carousel = "carouselItem";
export const Brand = "panel";
export const Category = "category";
export const Rating = "productRating";
export const Appointment = "doctorAppointment";
export const Notifications = "notification";
export const DoctorReview = "doctorReview";
export const couponCode = "coupon";
export const Testimonials = "panelReview";
export const Payment = "order";
